import {
  DocumentData,
  DocumentReference,
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { isEmpty, omit } from 'lodash';
import { useEffect, useState } from 'react';
import { SubmitHandler, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import { uid as uniqueId } from 'uid';
import { sendModifyPushNotification } from '../../../../api/axios';
import { Form, InnerPage, Sidebar } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { useLoading } from '../../../../context/LoadingContext';
import usePollsSchema from '../../../../hooks/schemas/usePollsSchema';
import {
  getAvailableContracts,
  getAvailableContractsIds,
} from '../../../../imports/contractsParsers';
import { db } from '../../../../imports/firebase';
import { AnswerForm, PollForm, QuestionForm } from '../../../../imports/types';
import { getDefaultDateTimeValue } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import { QuestionFields } from '../../components';
import PollsGeneral from '../../components/pollsCreation/PollsGeneral';

enum EStep {
  POLL_GENERAL,
  POLL_QUESTIONS,
}
const CreatePolls = () => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();
  const { workspace } = useAppSelector((state) => state.team);
  const profile = useAppSelector((state) => state.user);
  const { uid } = useAppSelector((state) => state.user);
  const [step, setStep] = useState(EStep.POLL_GENERAL);
  const pollSchema = usePollsSchema(step < EStep.POLL_QUESTIONS);
  const stepperLabels = [t('polls.titles.general'), t('polls.titles.questions')];
  // const { setLoading } = useLoading();
  const { isLoading, setLoading } = useLoading();

  const { id: pollId } = useParams();
  const [poll, setPoll] = useState<PollForm | null>(null);
  const [questionsIds, setQuestionsIds] = useState(['']);

  const { open: openLoadingModal } = useModal('LoadingModal');
  const goTo = (page: string) => {
    navigate(page);
  };
  const handleOpenLoadingModal = () => {
    openLoadingModal({ goTo, type: 'poll' });
  };

  useEffect(() => {
    if (pollId) {
      setLoading({ loading: true });

      const targetOwnerUid = workspace?.owner ?? uid;

      const pollDocument = query(
        collection(db, 'polls'),
        where('owner', '==', targetOwnerUid),
        where('id', '==', pollId)
      );
      const unsubscribePolls = onSnapshot(pollDocument, async (querySnapshot) => {
        const pollSnapshot: PollForm[] = [];
        querySnapshot.forEach((doc) => {
          pollSnapshot.push(doc.data() as PollForm);
        });
        if (pollSnapshot.length === 1) {
          setPoll(pollSnapshot[0]);
          setLoading({ loading: false });
        }
        //   else navigate('/nft/polls');
      });

      return () => {
        unsubscribePolls();
      };
    }
  }, [pollId]);

  const { defaultDate, defaultTime } = getDefaultDateTimeValue({ extraHours: 2 });

  const EMPTY_ANSWER: AnswerForm = {
    aId: uniqueId(4),
    answer: '',
    nextQuestion: '',
  };
  const EMPTY_QUESTION: QuestionForm = {
    qId: uniqueId(4),
    isFirst: true,
    question: '',
    minAnswers: 1,
    maxAnswers: 1,
    type: 'check',
    answers: [EMPTY_ANSWER],
  };

  const hasCycleDFS = (
    qId: string,
    questions: any[],
    visited: Set<unknown>,
    recursionStack: Set<unknown>
  ) => {
    visited.add(qId);
    recursionStack.add(qId);

    const currentQuestion = questions.find((q) => String(q.qId) === qId);

    if (currentQuestion) {
      for (const answer of currentQuestion.answers) {
        const nextQuestionId = String(answer.nextQuestion);
        if (!visited.has(nextQuestionId) && nextQuestionId !== '') {
          if (hasCycleDFS(nextQuestionId, questions, visited, recursionStack)) {
            return true;
          }
        } else if (recursionStack.has(nextQuestionId)) {
          return true;
        }
      }
    }

    recursionStack.delete(qId);
    return false;
  };

  const hasCycle = (questions: any) => {
    const visited = new Set();

    for (const question of questions) {
      if (!visited.has(String(question.qId))) {
        if (hasCycleDFS(String(question.qId), questions, visited, new Set())) {
          return true;
        }
      }
    }
    return false;
  };

  const detectCycles = (graph: any) => {
    const visited: any[] = [];
    const stack: any[] = [];
    const cycles = [];

    const dfs = (qId: any, visited: any[], stack: any[]) => {
      visited.push(qId);
      const currentNode = graph.find((node: { qId: any }) => String(node.qId) === String(qId));
      stack.push(qId);

      if (currentNode) {
        for (const answer of currentNode.answers) {
          const nextQuestionId = answer.nextQuestion;
          if (nextQuestionId) {
            if (!visited.includes(nextQuestionId)) {
              if (dfs(nextQuestionId, visited, stack)) {
                stack.push({ question: currentNode.qId, answer: answer.aId });
                stack.push(qId);
                return true;
              }
            } else if (stack.includes(nextQuestionId)) {
              stack.push({ question: currentNode.qId, answer: answer.aId });
              stack.push(qId);
              return true;
            }
          }
        }
      }
      delete stack[qId];
      return false;
    };

    for (const node of graph) {
      if (!visited.includes(node.qId)) {
        if (dfs(node.qId, visited, stack)) {
          cycles.push([...stack]);
        }
      }
    }
    return cycles.map((cycle) => cycle.filter((obj) => typeof obj === 'object'))?.[0];
  };

  const form = {
    initialValues: {
      id: poll?.id || '',
      private: poll?.private || false,
      img: poll?.img || '',
      title: poll?.title || '',
      body: poll?.body || '',
      startDate: defaultDate,
      startHours: defaultTime,
      endDate: defaultDate,
      endHours: defaultTime + 1,
      options: poll?.options || [],
      minTotalAnswers: 1,
      maxTotalAnswers: 1,
      submit: poll?.submit || 'db',
      questions: poll?.questions || [EMPTY_QUESTION],
      owner: workspace?.owner ?? uid,
      availableOn: poll?.availableOn || {},
      createdAt: poll?.createdAt || Date.now(),
      updatedAt: Date.now(),
    },
    validationSchema: pollSchema,
  };

  const reassignNextQuestionsIds = (
    values: any,
    setValue?: UseFormSetValue<any>,
    deletedId?: string
  ) => {
    const qIds = values.questions.map((q: { qId: string }) => q.qId);
    const deletedIndex = questionsIds.findIndex((x) => x === deletedId);
    //riassegna id next question
    for (const q in values.questions) {
      if (q) {
        // console.log(values.questions[q]);
        for (const a in values.questions[q].answers) {
          if (a) {
            switch (values.questions[q].answers[a].nextQuestion) {
              case '': //end
                if (setValue) {
                  setValue(`questions[${Number(q)}].answers[${Number(a)}].nextQuestion`, '');
                } else {
                  values.questions[Number(q)].answers[Number(a)].nextQuestion = '';
                }
                break;
              case 'nextQ': //nextQuestion
                try {
                  const nextId = values.questions[Number(q) + 1].qId;
                  if (setValue) {
                    setValue(`questions[${Number(q)}].answers[${Number(a)}].nextQuestion`, nextId);
                  } else {
                    values.questions[Number(q)].answers[Number(a)].nextQuestion = nextId;
                  }
                } catch (error) {
                  if (setValue) {
                    setValue(`questions[${Number(q)}].answers[${Number(a)}].nextQuestion`, '');
                  } else {
                    values.questions[Number(q)].answers[Number(a)].nextQuestion = '';
                  }
                }
                break;
              case deletedId: //domanda eliminata
                try {
                  const nextId = values.questions[deletedIndex].qId;
                  if (setValue) {
                    setValue(`questions[${Number(q)}].answers[${Number(a)}].nextQuestion`, nextId);
                  } else {
                    values.questions[Number(q)].answers[Number(a)].nextQuestion = nextId;
                  }
                } catch (error) {
                  if (setValue) {
                    setValue(`questions[${Number(q)}].answers[${Number(a)}].nextQuestion`, '');
                  } else {
                    values.questions[Number(q)].answers[Number(a)].nextQuestion = '';
                  }
                }
                break;
              default:
                // try {
                //   const nextId = values.questions[Number(q) + 1].qId;
                //   if (setValue) {
                //     setValue(`questions[${Number(q)}].answers[${Number(a)}].nextQuestion`, nextId);
                //   } else {
                //     values.questions[Number(q)].answers[Number(a)].nextQuestion = nextId;
                //   }
                // } catch (error) {
                //   if (setValue) {
                //     setValue(`questions[${Number(q)}].answers[${Number(a)}].nextQuestion`, '');
                //   } else {
                //     values.questions[Number(q)].answers[Number(a)].nextQuestion = '';
                //   }
                // }
                break;
            }
          }
        }
      }
    }
    setQuestionsIds(qIds);
  };

  const submit: SubmitHandler<typeof form.initialValues> = async (
    rawValues: PollForm,
    setValue
  ) => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('polls.actions.pending'),
      },
    });
    handleOpenLoadingModal();

    let newPollRef: DocumentReference<DocumentData>;
    if (poll !== null) {
      newPollRef = doc(collection(db, 'polls'), poll.id);
    } else {
      newPollRef = doc(collection(db, 'polls'));
    }
    try {
      if (rawValues.options) {
        let isSubmittable = true;
        const dropDateNoHours = new Date(rawValues.startDate).getTime();
        const expirationDateNoHours = new Date(rawValues.endDate).getTime();
        const dropDate = new Date(
          dropDateNoHours + rawValues.startHours * 60 * 60 * 1000
        ).getTime();
        const expirationDate = new Date(
          expirationDateNoHours + rawValues.endHours * 60 * 60 * 1000
        ).getTime();

        const unixPollStartDate = new Date(dropDate).getTime();
        const unixPollEndDate = new Date(expirationDate).getTime();
        const poll: PollForm = {
          ...omit(rawValues, ['options']),
          availableOn: getAvailableContracts(rawValues.options),
          startDate: unixPollStartDate,
          endDate: unixPollEndDate,
          id: newPollRef.id,
        };

        if (isEmpty(poll.availableOn)) {
          toast.error(toast.error(t('polls.errors.select_one_contract') as string));
          isSubmittable = false;
          setStep(EStep.POLL_GENERAL);
        }
        if (poll.startDate === 0 || poll.endDate === 0) {
          toast.error(t('polls.errors.missing_date') as string);
          isSubmittable = false;
          setStep(EStep.POLL_GENERAL);
        }
        if (unixPollEndDate < Date.now() || unixPollStartDate < Date.now()) {
          toast.error(t('polls.errors.date') as string);
          isSubmittable = false;
          setStep(EStep.POLL_GENERAL);
        }
        if (poll.endDate < poll.startDate) {
          toast.error(t('polls.errors.date') as string);
          isSubmittable = false;
          setStep(EStep.POLL_GENERAL);
        }
        if (poll.startDate === poll.endDate) {
          toast.error(t('polls.errors.same_date') as string);
          isSubmittable = false;
          setStep(EStep.POLL_GENERAL);
        }
        if (poll.questions.length < 1) {
          toast.error(t('polls.errors.add_one_question') as string);
          isSubmittable = false;
        }

        reassignNextQuestionsIds(poll);

        if (hasCycle(poll.questions)) {
          const loop = detectCycles(poll.questions);
          const stringLoop = loop
            .map((item) => {
              return 'Q:' + item.question + ' A:' + item.answer;
            })
            .join(' -> ');
          isSubmittable = false;
          toast.error(
            t('polls.errors.loop', {
              loop: stringLoop,
            }) as string
          );
        }

        if (isSubmittable) {
          await setDoc(newPollRef, poll);
          // getAvailableContractsIds(rawValues.options).forEach(async (contract) => {
          //   if(poll.startDate  >  Date.now())
          //   await sendModifyPushNotification(uid, contract.contractId, contract.nftsIds);
          // });

          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('polls.actions.success'),
            },
          });
        } else {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              message: t('polls.actions.error'),
            },
          });
        }
      }
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('polls.actions.error'),
        },
      });
    }
  };

  return (
    <InnerPage>
      <div className="flex flex-row">
        <Sidebar
          stepperCurrent={step}
          stepperLabels={stepperLabels}
          title={t('polls.titles.sidebar_title')}
          subtitle={t('polls.titles.sidebar_subtitle', {
            companyName: profile.name || profile.company,
          })}
        />
        <Form
          initialValues={form.initialValues}
          validationSchema={form.validationSchema}
          className="ml-[max(min(30vw,420px),360px)] flex-1 px-8"
        >
          {({
            fields,
            errors,
            setValue,
            handleSubmit,
            watch,
            control,
            resetField,
            register,
            clearErrors,
          }) => {
            const values = watch();
            if (errors.startDate?.type === 'startDate') {
              toast.warning(t('polls.errors.date_start_higher_than_end') as string);
            }
            if (
              errors.startHours?.type === 'compareTime' ||
              errors.endHours?.type === 'startHours'
            ) {
              toast.warning(t('polls.errors.same_day_end_hour_lower') as string);
            }
            if (errors.startHours?.type === 'twoHoursAdvance') {
              toast.warning(t('polls.errors.start_2_hour_later') as string);
            }
            useEffect(() => {
              const qIds = values.questions.map((q: { qId: string }) => q.qId);
              if (questionsIds.length > qIds.length) {
                const diff = questionsIds
                  .filter((x) => !qIds.includes(x))
                  .concat(qIds.filter((x: any) => !questionsIds.includes(x)));
                reassignNextQuestionsIds(values, setValue, diff[0]);
              }
              setQuestionsIds(qIds);
            }, [values.questions.length]);

            return (
              <section className="mt-[32px] flex w-full flex-col space-y-[12px]">
                {step === EStep.POLL_GENERAL && (
                  <PollsGeneral
                    errors={errors}
                    fields={fields}
                    control={control}
                    setValue={setValue}
                    values={values}
                    watch={watch}
                    onCompleted={handleSubmit(() => {
                      setStep(EStep.POLL_QUESTIONS);
                      window.scrollTo(0, 0);
                    })}
                  />
                )}
                {step === EStep.POLL_QUESTIONS && (
                  <QuestionFields
                    values={values}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    resetField={resetField}
                    register={register}
                    onSubmit={handleSubmit(submit)}
                    clearErrors={clearErrors}
                    onBack={() => setStep(EStep.POLL_GENERAL)}
                  />
                )}
              </section>
            );
          }}
        </Form>
      </div>
    </InnerPage>
  );
};

export default CreatePolls;
