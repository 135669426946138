import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { Button, InnerPage, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { IconLoading } from '../../../../icons';
import { auth } from '../../../../imports/firebase';
import { Message } from '../../../../imports/types';
import { formatTimestamp, truncateText } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import { getMessages } from '../../api';

const Messages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['tokenCreator']);
  const { open: openNewMessage } = useModal('NewMessageModal');
  const { open: openMessage } = useModal('ReadMessageModal');
  const { open: openMessageCollections } = useModal('MessagesCollectionsModal');
  const { workspace } = useAppSelector((state) => state.team);
  const { state } = useLoadingStatusContext();

  const navigate = useNavigate();
  const [messagesSent, setMessagesSent] = useState<Message[]>();
  const [messagesScheduled, setMessagesScheduled] = useState<Message[]>();


  enum Pages {
    SENT,
    SCHEDULED,
    EXPIRED,
  }
  const [page, setPage] = useState(Pages.SENT);

  const handleNavigate = (route: string) => {
    navigate(route);
  };
  const handleNewMessage = () => {
    openNewMessage(handleNavigate);
  };

  const handleCollectionsModal = (message: Message) => {
    openMessageCollections({ message });
  };

  const getAllMessages = async () => {
    try {
      if (!auth.currentUser) return;
      const messagesObject = await getMessages();
      const messages = Object.values(messagesObject);
      messages.sort((a: Message, b: Message) => b.createdAt - a.createdAt);
      const sent: Message[] = [];
      const scheduled: Message[] = [];
      const today = Date.now();
      messages.forEach((message) => {
        if (!message.dropDate || message.dropDate < today) {
          sent.push(message);
        }
        if (message.dropDate && message.dropDate > today) {
          scheduled.push(message);
        }
      });
      setMessagesSent(sent);
      setMessagesScheduled(scheduled);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      try {
        getAllMessages();
        setPage(Pages.SENT);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }, 1000);
  }, [auth.currentUser]);

  // Listen to the state.status for messages refresh
  useEffect(() => {
    if (state.status === 'success') {
      getAllMessages();
    }
  }, [state]);
  return (
    <>
      <InnerPage>
        <div className="">
          {isLoading ? (
            <div className="mt-[100px] flex grow items-center justify-center">
              <IconLoading className="size-12 animate-spin text-primary-500" />
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between pb-[60px] pt-[30px]">
                <Typography size="body-medium-30" color="black">
                  {t('messages.messages')}
                </Typography>
              </div>
              <div className="flex justify-between w-full">
                <div className="flex gap-4">
                  <Button
                    type={page === Pages.SENT ? 'primary' : 'tertiary'}
                    action={() => setPage(Pages.SENT)}
                  >
                    <span className="flex items-center gap-3 ">
                      <span>{t('messages.sent')}</span>

                      <span
                        className={`flex h-[20px] w-[20px] items-center justify-center rounded-full  text-center text-[10px]  ${
                          page === Pages.SENT
                            ? 'bg-white text-primary-500'
                            : 'bg-primary-500 text-white'
                        }`}
                      >
                        {messagesSent?.length}
                      </span>
                    </span>
                  </Button>

                  <Button
                    type={page === Pages.SCHEDULED ? 'primary' : 'tertiary'}
                    action={() => setPage(Pages.SCHEDULED)}
                  >
                    <span className="flex items-center gap-3">
                      <span>{t('messages.scheduled')}</span>

                      <span
                        className={`flex h-[20px] w-[20px] items-center justify-center rounded-full  text-center text-[10px]  ${
                          page === Pages.SCHEDULED
                            ? 'bg-white text-primary-500'
                            : 'bg-primary-500 text-white'
                        }`}
                      >
                        {messagesScheduled?.length}
                      </span>
                    </span>
                  </Button>
                </div>
                {/* {workspace?.permissions?.token_creator?.write && ( */}
                {workspace?.isOwner && (
                  <Button action={handleNewMessage}>{t('messages.new_message')}</Button>
                )}
              </div>
              <div className="mt-10 shadow text-body-medium-18 sm:rounded-lg">
                <div className="grid h-[65px] w-full grid-cols-6 items-center border-b text-center text-black">
                  <Typography
                    size="body-medium-18"
                    weight="medium"
                    className="col-span-2 pl-4 text-left"
                  >
                    {t('messages.title').toUpperCase()}
                  </Typography>
                  <Typography
                    size="body-medium-18"
                    weight="medium"
                    className="col-span-2 pl-4 text-left"
                  >
                    {t('messages.text').toUpperCase()}
                  </Typography>
                  <Typography
                    size="body-medium-18"
                    weight="medium"
                    className="col-span-1 pl-4 text-left"
                  >
                    {t('messages.collections').toUpperCase()}
                  </Typography>
                  <Typography
                    size="body-medium-18"
                    weight="medium"
                    className="col-span-1 pl-4 text-left"
                  >
                    {t('messages.date').toUpperCase()}
                  </Typography>
                </div>

                {page === Pages.SENT &&
                  messagesSent &&
                  messagesSent.length > 0 &&
                  messagesSent.map((message: Message, i) => {
                    const created = formatTimestamp(message.createdAt);
                    const rowClass = i % 2 === 0 ? 'bg-white' : 'bg-grey-100';
                    return (
                      <div
                        key={message.title + '_' + i}
                        className={`grid h-[65px] w-full cursor-pointer grid-cols-6 items-center ${rowClass} text-center text-black hover:bg-grey-200`}
                      >
                        <div
                          className="col-span-2 pl-4 text-left"
                          onClick={() => openMessage({ msg: message })}
                        >
                          <Typography size="body-medium-18" weight="medium">
                            {truncateText(message.title, {
                              maxLength: 40,
                              rightChars: 0,
                            })}
                          </Typography>
                        </div>
                        <div
                          className="col-span-2 pl-4 text-left"
                          onClick={() => openMessage({ msg: message })}
                        >
                          <Typography size="body-medium-18" weight="medium">
                            {truncateText(message.description, {
                              maxLength: 65,
                              rightChars: 0,
                            })}
                          </Typography>
                        </div>
                        <Button
                          type="secondary"
                          className="!h-[30px] rounded-[4px]"
                          action={() => handleCollectionsModal(message)}
                          textSize="md"
                        >
                          {t('messages.collections')}
                        </Button>
                        <div onClick={() => openMessage({ msg: message })}>
                          <Typography
                            size="body-medium-18"
                            weight="medium"
                            className="col-span-1 pl-4 text-left"
                          >
                            {created}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                {page === Pages.SCHEDULED &&
                  messagesScheduled &&
                  messagesScheduled.length > 0 &&
                  messagesScheduled.map((message: Message, i) => {
                    const created = formatTimestamp(message.dropDate!);
                    const rowClass = i % 2 === 0 ? 'bg-white' : 'bg-grey-100';
                    return (
                      <div
                        key={message.title + '_' + i}
                        className={`grid h-[65px] w-full cursor-pointer grid-cols-6 items-center ${rowClass} text-center text-black hover:bg-grey-200`}
                      >
                        <div
                          className="col-span-2 pl-4 text-left"
                          onClick={() => openMessage({ msg: message })}
                        >
                          <Typography size="body-medium-18" weight="medium">
                            {truncateText(message.title, {
                              maxLength: 40,
                              rightChars: 0,
                            })}
                          </Typography>
                        </div>
                        <div
                          className="col-span-2 pl-4 text-left"
                          onClick={() => openMessage({ msg: message })}
                        >
                          <Typography size="body-medium-18" weight="medium">
                            {truncateText(message.description, {
                              maxLength: 65,
                              rightChars: 0,
                            })}
                          </Typography>
                        </div>
                        <Button
                          type="secondary"
                          className="!h-[30px] rounded-[4px]"
                          action={() => handleCollectionsModal(message)}
                          textSize="md"
                        >
                          {t('messages.collections')}
                        </Button>
                        <div onClick={() => openMessage({ msg: message })}>
                          <Typography
                            size="body-medium-18"
                            weight="medium"
                            className="col-span-1 pl-4 text-left"
                          >
                            {created}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </InnerPage>
    </>
  );
};

export default Messages;
